import actionTypes from "./actionTypes";

const initState = () => ({
  isAuthenticated: false,
  publicIdentifier: process.env.JWT_PUBLIC_IDENTIFIER,
  csrfToken: null,
  referral_code: null,
  hasPhoneNumber: false,
  isPhoneValidated: false,
  is2FAActive: false,
  is2FAPrompted: false,
  deviceUUID: typeof window !== 'undefined' && localStorage.getItem(actionTypes.DEVICE_UUID) || '',
  cp_session_token: null,
});

const loginSuccess = (state, { hasPhoneNumber = true, cp_session_token, deviceUUID, isPhoneValidated, is2FAActive }) => {
  // To save deviceId separate than redux state
  if (deviceUUID) localStorage.setItem(actionTypes.DEVICE_UUID, deviceUUID);

  return {
    ...state,
    publicIdentifier: null,
    isAuthenticated: true,
    referral_code: null,
    cp_session_token,
    hasPhoneNumber,
    isPhoneValidated,
    is2FAActive,
    deviceUUID: deviceUUID || state.deviceUUID || localStorage.getItem(actionTypes.DEVICE_UUID),
  };
};

const clearCustomerState = (state) => ({
  ...initState(),
  csrfToken: state.csrfToken,
  referral_code: state.referral_code
});

const setHasPhoneNumber = (state, { hasPhoneNumber }) => ({
  ...state,
  hasPhoneNumber
});

const setPhoneValidated = (state, { isPhoneValidated, cp_session_token }) => ({
  ...state,
  isAuthenticated: true,
  publicIdentifier: null,
  isPhoneValidated,
  cp_session_token: isPhoneValidated ? cp_session_token : state.cp_session_token,
});

const signUp = (state) => ({
  ...state,
  // publicIdentifier: null,
  isAuthenticated: false,
  referral_code: null,
});

const authReducers = (state = initState(), action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action.payload);
    case actionTypes.SIGN_UP_SUCCESS:
      return signUp(state);
    case actionTypes.CSRF_TOKEN:
      return { ...state, csrfToken: action.payload.csrfToken };
    case actionTypes.SET_REFERRAL_CODE:
      return { ...state, referral_code: action.payload };
    case actionTypes.CLEAR_STATE:
      return clearCustomerState(state);
    case actionTypes.SET_HAS_PHONE_NUMBER:
      return setHasPhoneNumber(state, action.payload);
    case actionTypes.SET_PHONE_VALIDATED:
      return setPhoneValidated(state, action.payload);
    case actionTypes.DEVICE_UUID:
      return { ...state, deviceUUID: action.payload };
    case actionTypes.SET_2FA_PROMPT:
      return { ...state, is2FAPrompted: action.payload };
    default:
      return state;
  }
};

export default authReducers;